import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { environment } from '../../../environments/environment';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { IntlService } from './intl.service';
import { ToolsService } from './tools.service';

declare const dataLayer;
declare const ttq;
declare const gtag;

export const mixpanelAllowedUrls = [
  'https:\/\/buk\.(pt|app|es)\/?$',
  'https:\/\/buk\.(pt|app|es)\/[a-zA-Z0-9_-]+\/dashboard\/.*',
  'https:\/\/buk\.(pt|app|es)\/dashboard',
  'https:\/\/buk\.(pt|app|es)\/[a-zA-Z0-9_-]+\/editor',
  'https:\/\/buk\.(pt|app|es)\/agenda',
  'https:\/\/buk\.(pt|app|es)\/pagina-marcacoes',
  'https:\/\/buk\.(pt|app|es)\/login',
  'https:\/\/buk\.(pt|app|es)\/signup',
  'https:\/\/buk\.(pt|app|es)\/calendar',
  'https:\/\/buk\.(pt|app|es)\/pagina-marcacoes',
  'https:\/\/buk\.(pt|app|es)\/booking-page',
  'https:\/\/buk\.(pt|app|es)\/barbeiros',
  'https:\/\/buk\.(pt|app|es)\/barbers',
  'https:\/\/buk\.(pt|app|es)\/cabeleireiros',
  'https:\/\/buk\.(pt|app|es)\/hairdressers',
  'https:\/\/buk\.(pt|app|es)\/nail-artists',
  'https:\/\/buk\.(pt|app|es)\/makeup-artists',
  'https:\/\/buk\.(pt|app|es)\/personal-trainers',
  'https:\/\/buk\.(pt|app|es)\/reiki',
  'https:\/\/buk\.(pt|app|es)\/nutricionistas',
  'https:\/\/buk\.(pt|app|es)\/nutritionists',
  'https:\/\/buk\.(pt|app|es)\/fisioterapeutas',
  'https:\/\/buk\.(pt|app|es)\/physiotherapists',
  'https:\/\/buk\.(pt|app|es)\/osteopatas',
  'https:\/\/buk\.(pt|app|es)\/osteopaths',
  'https:\/\/buk\.(pt|app|es)\/dentistas',
  'https:\/\/buk\.(pt|app|es)\/dentists',
  'https:\/\/buk\.(pt|app|es)\/psicologos',
  'https:\/\/buk\.(pt|app|es)\/psychologists',
  'https:\/\/buk\.(pt|app|es)\/farmacias',
  'https:\/\/buk\.(pt|app|es)\/pharmacies',
  'https:\/\/buk\.(pt|app|es)\/centros-de-terapias',
  'https:\/\/buk\.(pt|app|es)\/therapy-center',
  'https:\/\/buk\.(pt|app|es)\/ginasios',
  'https:\/\/buk\.(pt|app|es)\/gyms',
  'https:\/\/buk\.(pt|app|es)\/homeopatia',
  'https:\/\/buk\.(pt|app|es)\/homeopathy',
  'https:\/\/buk\.(pt|app|es)\/acupuntura',
  'https:\/\/buk\.(pt|app|es)\/acupuncture',
  'https:\/\/buk\.(pt|app|es)\/spa-massagens',
  'https:\/\/buk\.(pt|app|es)\/spa-massages',
  'https:\/\/buk\.(pt|app|es)\/pet-care',
  'https:\/\/buk\.(pt|app|es)\/signup/merchant/.*',
  'https:\/\/buk\.(pt|app|es)\/setup/.*',
  'https:\/\/buk\.(pt|app|es)\/recoverpass',
  'https:\/\/buk\.(pt|app|es)\/setpassword',
  'https:\/\/buk\.(pt|app|es)\/logout',
  'https:\/\/buk\.(pt|app|es)\/terms',
  'https:\/\/buk\.(pt|app|es)\/privacy',
  'https:\/\/buk\.(pt|app|es)\/cookie-policy',
  'https:\/\/buk\.(pt|app|es)\/faqs',
  'https:\/\/buk\.(pt|app|es)\/support',
  'https:\/\/buk\.(pt|app|es)\/cancel-subscription',
  'https:\/\/buk\.(pt|app|es)\/pay/mbway/.*',
]
export const posthogAllowedUrls = [
  'https:\/\/buk\.(pt|app|es)\/?$',
  'https:\/\/buk\.(pt|app|es)\/[a-zA-Z0-9_-]+\/dashboard\/.*',
  'https:\/\/buk\.(pt|app|es)\/[a-zA-Z0-9_-]+\/dashboard\/clients',
  'https:\/\/buk\.(pt|app|es)\/[a-zA-Z0-9_-]+\/dashboard\/stats',
  'https:\/\/buk\.(pt|app|es)\/[a-zA-Z0-9_-]+\/dashboard\/marketing',
  'https:\/\/buk\.(pt|app|es)\/[a-zA-Z0-9_-]+\/dashboard\/extensions',
  'https:\/\/buk\.(pt|app|es)\/[a-zA-Z0-9_-]+\/editor',
  'https:\/\/buk\.(pt|app|es)\/agenda',
  'https:\/\/buk\.(pt|app|es)\/pagina-marcacoes',
  'https:\/\/buk\.(pt|app|es)\/login',
  'https:\/\/buk\.(pt|app|es)\/signup',
  'https:\/\/buk\.(pt|app|es)\/calendar',
  'https:\/\/buk\.(pt|app|es)\/pagina-marcacoes',
  'https:\/\/buk\.(pt|app|es)\/booking-page',
  'https:\/\/buk\.(pt|app|es)\/barbeiros',
  'https:\/\/buk\.(pt|app|es)\/barbers',
  'https:\/\/buk\.(pt|app|es)\/cabeleireiros',
  'https:\/\/buk\.(pt|app|es)\/hairdressers',
  'https:\/\/buk\.(pt|app|es)\/nail-artists',
  'https:\/\/buk\.(pt|app|es)\/makeup-artists',
  'https:\/\/buk\.(pt|app|es)\/personal-trainers',
  'https:\/\/buk\.(pt|app|es)\/reiki',
  'https:\/\/buk\.(pt|app|es)\/nutricionistas',
  'https:\/\/buk\.(pt|app|es)\/nutritionists',
  'https:\/\/buk\.(pt|app|es)\/fisioterapeutas',
  'https:\/\/buk\.(pt|app|es)\/physiotherapists',
  'https:\/\/buk\.(pt|app|es)\/osteopatas',
  'https:\/\/buk\.(pt|app|es)\/osteopaths',
  'https:\/\/buk\.(pt|app|es)\/dentistas',
  'https:\/\/buk\.(pt|app|es)\/dentists',
  'https:\/\/buk\.(pt|app|es)\/psicologos',
  'https:\/\/buk\.(pt|app|es)\/psychologists',
  'https:\/\/buk\.(pt|app|es)\/farmacias',
  'https:\/\/buk\.(pt|app|es)\/pharmacies',
  'https:\/\/buk\.(pt|app|es)\/centros-de-terapias',
  'https:\/\/buk\.(pt|app|es)\/therapy-center',
  'https:\/\/buk\.(pt|app|es)\/ginasios',
  'https:\/\/buk\.(pt|app|es)\/gyms',
  'https:\/\/buk\.(pt|app|es)\/homeopatia',
  'https:\/\/buk\.(pt|app|es)\/homeopathy',
  'https:\/\/buk\.(pt|app|es)\/acupuntura',
  'https:\/\/buk\.(pt|app|es)\/acupuncture',
  'https:\/\/buk\.(pt|app|es)\/spa-massagens',
  'https:\/\/buk\.(pt|app|es)\/spa-massages',
  'https:\/\/buk\.(pt|app|es)\/pet-care',
  'https:\/\/buk\.(pt|app|es)\/signup/merchant/.*',
  'https:\/\/buk\.(pt|app|es)\/setup/.*',
  'https:\/\/buk\.(pt|app|es)\/logout',
  'https:\/\/buk\.(pt|app|es)\/terms',
  'https:\/\/buk\.(pt|app|es)\/privacy',
  'https:\/\/buk\.(pt|app|es)\/cookie-policy',
  'https:\/\/buk\.(pt|app|es)\/faqs',
  'https:\/\/buk\.(pt|app|es)\/support',
  'https:\/\/buk\.(pt|app|es)\/cancel-subscription',
  'https:\/\/buk\.(pt|app|es)\/pay/mbway/.*',
];

export const dealfrontAllowedUrls = [
  'https:\/\/buk\.(pt|app|es)\/?$',
  'https:\/\/buk\.(pt|app|es)\/terms',
  'https:\/\/buk\.(pt|app|es)\/privacy',
  'https:\/\/buk\.(pt|app|es)\/cookie-policy',
  'https:\/\/buk\.(pt|app|es)\/faqs',
  'https:\/\/buk\.(pt|app|es)\/support',
  'https:\/\/buk\.(pt|app|es)\/agenda',
  'https:\/\/buk\.(pt|app|es)\/pagina-marcacoes',
  'https:\/\/buk\.(pt|app|es)\/login',
  'https:\/\/buk\.(pt|app|es)\/signup',
  'https:\/\/buk\.(pt|app|es)\/calendar',
  'https:\/\/buk\.(pt|app|es)\/pagina-marcacoes',
  'https:\/\/buk\.(pt|app|es)\/booking-page',
  'https:\/\/buk\.(pt|app|es)\/barbeiros',
  'https:\/\/buk\.(pt|app|es)\/barbers',
  'https:\/\/buk\.(pt|app|es)\/cabeleireiros',
  'https:\/\/buk\.(pt|app|es)\/hairdressers',
  'https:\/\/buk\.(pt|app|es)\/nail-artists',
  'https:\/\/buk\.(pt|app|es)\/makeup-artists',
  'https:\/\/buk\.(pt|app|es)\/personal-trainers',
  'https:\/\/buk\.(pt|app|es)\/reiki',
  'https:\/\/buk\.(pt|app|es)\/nutricionistas',
  'https:\/\/buk\.(pt|app|es)\/nutritionists',
  'https:\/\/buk\.(pt|app|es)\/fisioterapeutas',
  'https:\/\/buk\.(pt|app|es)\/physiotherapists',
  'https:\/\/buk\.(pt|app|es)\/osteopatas',
  'https:\/\/buk\.(pt|app|es)\/osteopaths',
  'https:\/\/buk\.(pt|app|es)\/dentistas',
  'https:\/\/buk\.(pt|app|es)\/dentists',
  'https:\/\/buk\.(pt|app|es)\/psicologos',
  'https:\/\/buk\.(pt|app|es)\/psychologists',
  'https:\/\/buk\.(pt|app|es)\/farmacias',
  'https:\/\/buk\.(pt|app|es)\/pharmacies',
  'https:\/\/buk\.(pt|app|es)\/centros-de-terapias',
  'https:\/\/buk\.(pt|app|es)\/therapy-center',
  'https:\/\/buk\.(pt|app|es)\/ginasios',
  'https:\/\/buk\.(pt|app|es)\/gyms',
  'https:\/\/buk\.(pt|app|es)\/homeopatia',
  'https:\/\/buk\.(pt|app|es)\/homeopathy',
  'https:\/\/buk\.(pt|app|es)\/acupuntura',
  'https:\/\/buk\.(pt|app|es)\/acupuncture',
  'https:\/\/buk\.(pt|app|es)\/spa-massagens',
  'https:\/\/buk\.(pt|app|es)\/spa-massages',
  'https:\/\/buk\.(pt|app|es)\/pet-care',
];

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private posthogUpdated = false;

  constructor(private angulartics: Angulartics2, @Inject(PLATFORM_ID) protected platformId: Object, public intlService: IntlService, private toolsService: ToolsService) {}

  trackEvent(action, category, label?, value?) {
    if (!environment.production) {
      return;
    }

    this.angulartics.eventTrack.next({
      action, // is the string that will appear as the event action in Google Analytics Event reports ie: sign_up, login
      properties: {
        event: 'eventTracking',
        category, // is the string that will appear as the event category ie: engagement
        label, // is the string that will appear as the event label ie: social, email
        value, // is a non-negative integer that will appear as the event value.
      },
    });

    this.trackG4Event({action, category, label, value})

    if (action === 'create_merchant') {
      this.trackLinkedInPixel('3557932');
    }
  }

  trackClientEvent(tagID, action: 'conversion' | 'payment', value?, currency?) {
    if (typeof gtag !== 'undefined' && tagID) {
        gtag('event', action, { 'send_to': tagID });
    }
  }

  trackClientPageView(tagID, host, slug) {
    if (typeof gtag !== 'undefined' && tagID) {
      gtag('event', 'page_view', {
        page_title: slug,
        page_path: '/' + slug,
        page_location: host + '/' + slug,
        'send_to': tagID
      });
    }
  }

  trackG4Event({
    action,
    category,
    label,
    value,
  }) {

    if (isPlatformServer(this.platformId)) {
      return;
    }

    window['dataLayer']?.push({
      event: 'ga4' + action,
      action,
      category,
      label,
      value
    });

  }

  trackProductAnalyticsPageView(url: string) {
    if (!environment.production) {
      return;
    }
    url = 'https://' + this.intlService.getHost() + url;

    const mixpanelRegexes = mixpanelAllowedUrls;
    // test if url matches any of the regular expressions in the array of strings
    const mixpanelMatch = mixpanelRegexes.some((regex) => {
      const allowed = new RegExp(regex).test(url)
      return allowed;
    });
    if (mixpanelMatch && mixpanel?.__loaded) {
        mixpanel.track_pageview();
    }

    const posthogRegexes = posthogAllowedUrls;
    const posthogMatch = posthogRegexes.some((regex) => {
      const allowed = new RegExp(regex).test(url)
      return allowed;
    });
    if (posthogMatch && posthog) {
        posthog.capture('$pageview');
    }
  }

  trackProductAnalyticsEvent(action, metaData = {}) {
    if (!environment.production) {
      return;
    }
    if (mixpanel?.__loaded) {
      mixpanel.track(action, {...metaData});
    }
    if (posthog) {
      posthog.capture(action, {...metaData});
    }
  }

  recordPosthogSessionStart() {
    if (!environment.production) {
      return;
    }
    if (posthog) {
      posthog.startSessionRecording();
    }
  }

  setUserId(merchant: any) {
    if (!environment.production) {
      return;
    }
    const merchantData = {
      numberOfProviders: merchant?.numberOfProviders,
      activeBookingPage: merchant?.activeBookingPage,
      paymentStatus: merchant?.paymentStatus,
      businessType: merchant?.type,
      hasCard: merchant?.hasCard,
      allowSepa: merchant?.allowSepa,
      numberOfStores: merchant?.stores?.length,
      merchantSlug: merchant?.slug
    };
    if (mixpanel?.__loaded) {
      mixpanel.identify(merchant.id);
      mixpanel.people.set(merchantData);
    }

    // prevent duplicate event to posthog in same session
    if (posthog && !this.posthogUpdated) {
      this.posthogUpdated = true;
      posthog.identify(
        merchant?.id?.toString(),
        { name: merchant.slug,
        ...merchantData }
      );
    }

    this.angulartics.setUsername.next(merchant.slug);

    this.indentifyTikTokUser(merchant.email, merchant.phone, merchant.id);
  }

  async indentifyTikTokUser(email: any, phone: string, id: string) {
    if (!environment.production) {
      return;
    }
    email = await this.toolsService.sha256Hash(email);
    phone = await this.toolsService.sha256Hash(phone);
    id = await this.toolsService.sha256Hash(id);

    try {
      ttq?.identify({
        "email": email,
        "phone_number": phone,
        "external_id": id
      })
    } catch (error) {

    }
  }

  trackTikTokPixel(event: 'ClickButton' | 'CompleteRegistration' | 'ViewContent', contentName: string) {
    try {
      ttq?.track(event, {
        "contents": [
          {
            "content_name": contentName
          }
        ]
      })
    } catch (error) {

    }
  }

  trackLinkedInPixel(conversionId) {
    // if (!environment.production || isPlatformServer(this.platformId)) {
    //   return;
    // }
    // const partnerId = (window as any)._linkedin_data_partner_ids[0];

    // let url = `https://px.ads.linkedin.com/collect/?pid=${partnerId}&fmt=gif`;
    // if (conversionId) {
    //   url = `${url}&conversionId=${conversionId}`;
    // }

    // const element = document.createElement('img');
    // element.alt = '';
    // element.height = 1;
    // element.width = 1;
    // element.src = url;
    // const body = document.getElementsByTagName('body')[0];
    // body.appendChild(element);
  }
}
